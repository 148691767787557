import accordions from "./accordions";
import awards from "./awards";
import banner from "./banner";
import blockquote from "./blockquote";
import clientStories from "./clientStories";
import comparison from "./comparison";
import ctaBlock from "./ctaBlock";
import form from "./form";
import heroVideo from "./heroVideo";
import industries from "./industries";
import itemScroller from "./itemScroller";
import jobRoles from "./jobRoles";
import logos from "./logos";
import map from "./map";
import sbsTextSlider from "./sbsTextSlider";
import sbsText from "./sbsText";
import sbsTextColumns from "./sbsTextColumns";
import sectionBanner from "./sectionBanner";
import slidingCarousel from "./slidingCarousel";
import solutions from "./solutions";
import stats from "./stats";
import team from "./team";
import verticalSlider from "./verticalSlider";
import video from "./video";
import windowBanner from "./windowBanner";
import wysiwyg from "./wysiwyg";

const components = (postType) => (
    `components {
        ${accordions(postType)}
        ${awards(postType)}
        ${banner(postType)}
        ${blockquote(postType)}
        ${clientStories(postType)}
        ${comparison(postType)}
        ${ctaBlock(postType)}
        ${form(postType)}
        ${heroVideo(postType)}
        ${industries(postType)}
        ${itemScroller(postType)}
        ${jobRoles(postType)}
        ${logos(postType)}
        ${map(postType)}
        ${sbsTextSlider(postType)}
        ${sbsText(postType)}
        ${sbsTextColumns(postType)}
        ${sectionBanner(postType)}
        ${slidingCarousel(postType)}
        ${solutions(postType)}
        ${stats(postType)}
        ${team(postType)}
        ${verticalSlider(postType)}
        ${video(postType)}
        ${windowBanner(postType)}
        ${wysiwyg(postType)}
    }`
);

export default components;
