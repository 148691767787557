const map = (postType) => (`
    ... on ${postType}_Components_Components_Map {
        content
        fieldGroupName
        title
        settings {
            showComponentTitle
            spacing
        }
        animationUrl
        link {
            url
            title
        }
    }
`
);

export default map;
