const comparison = (postType) => (`
    ... on ${postType}_Components_Components_Comparison {
        content
        fieldGroupName
        title
        table {
            body
            caption
            header
        }
        settings {
            showComponentTitle
            spacing
            theme
        }
    }
`
);

export default comparison;
