const clientStories = (postType) => (`
    ... on ${postType}_Components_Components_ClientStories {
        fieldGroupName
        title
        settings {
            backgroundOverlay {
                showBackgroundOverlay
                backgroundOverlayStrength
            }
            showComponentTitle
            spacing
        }
        quotes {
            quote
            logo {
                altText
                sizes(size: LARGE)
                sourceUrl(size: LARGE)
                srcSet(size: LARGE)
                title
            }
            backgroundImage {
                altText
                sizes(size: _1920X1080)
                sourceUrl(size: _1920X1080)
                srcSet(size: _1920X1080)
                title
            }
            author {
                authorSubtitle
                authorName
                authorImage {
                    altText
                    sizes(size: MEDIUM)
                    sourceUrl(size: MEDIUM)
                    srcSet(size: MEDIUM)
                    title
                }
            }
            link {
                target
                title
                url
            }
        }
        image {
            altText
            sizes(size: _1920X1080)
            sourceUrl(size: _1920X1080)
            srcSet(size: _1920X1080)
            title
        }
    }
`
);

export default clientStories;
