const team = (postType) => (`
    ... on ${postType}_Components_Components_Team {
        fieldGroupName
        title
        contentOptions
        people {
            ... on Person {
                id
                title
                person {
                    description
                    position
                    featuredImage {
                        sizes
                        srcSet
                        sourceUrl
                    }
                    linkedin
                    featuredLink {
                        url
                    }
                }
                personCategories {
                    edges {
                        node {
                            name
                            databaseId
                        }
                    }
                }
            }
        }
        settings {
            fieldGroupName
            showCategorySelector
            theme
            showComponentTitle
            spacing
        }
    }
`
);

export default team;
