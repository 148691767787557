const accordions = (postType) => (
    `... on ${postType}_Components_Components_Accordions {
        content
        fieldGroupName
        displayType
        listFormat
        accordions {
            content
            title
            icon {
                altText
                title
                mimeType
                sourceUrl
            }
        }
        image {
            altText
            title
            sizes(size: LARGE)
            sourceUrl(size: LARGE)
            srcSet(size: LARGE)
        }
        title
        settings {
            theme
            showComponentTitle
            spacing
        }
    }`
);

export default accordions;
