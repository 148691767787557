const itemScroller = (postType) => (
    `... on ${postType}_Components_Components_ItemScroller {
        fieldGroupName
        title
        contentOptions
        itemScrollerCategory
        availablePostTypes
        items {
            ... on Event {
                title
                slug
                event {
                    excerpt
                    featuredImage {
                        sizes(size: LARGE)
                        sourceUrl(size: LARGE)
                        srcSet(size: LARGE)
                    }
                }
                contentTypeName
            }
            ... on Person {
                id
                title
                contentTypeName
                person {
                    description
                    position
                    featuredImage {
                        sourceUrl(size: LARGE)
                        srcSet(size: LARGE)
                        sizes(size: LARGE)
                    }
                }
            }
            ... on Post {
                title
                slug
                contentTypeName
                post {
                    excerpt
                    featuredImage {
                        sizes(size: LARGE)
                        sourceUrl(size: LARGE)
                        srcSet(size: LARGE)
                    }
                }
            }
            ... on ClientStory {
                title
                slug
                contentTypeName
                clientStory {
                    excerpt
                    featuredImage {
                        sizes(size: LARGE)
                        sourceUrl(size: LARGE)
                        srcSet(size: LARGE)
                    }
                }
            }
            ... on Resource {
                title
                slug
                link
                contentTypeName
                resource {
                    excerpt
                    featuredImage {
                        sizes(size: LARGE)
                        sourceUrl(size: LARGE)
                        srcSet(size: LARGE)
                    }
                    file {
                        mediaItemUrl
                    }
                    resourceType
                    link {
                        url
                    }
                }
            }
        }
        people {
            ... on Person {
                id
                title
                person {
                    description
                    position
                    featuredImage {
                        sourceUrl(size: LARGE)
                        srcSet(size: LARGE)
                        sizes(size: LARGE)
                    }
                }
            }
        }
        settings {
            showComponentTitle
            spacing
            theme
        }
    }`
);

export default itemScroller;
