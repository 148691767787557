const banner = (postType) => (`
    ... on ${postType}_Components_Components_Banner {
        fieldGroupName
        slides {
            title
            logoImage {
                altText
                title
                sizes(size: LARGE)
                sourceUrl(size: LARGE)
                srcSet(size: LARGE)
            }
            content
            link {
                target
                title
                url
            }
            theme
            videoSource
            video
            vimeoVideoId
            localVideo {
                link
                mimeType
            }
            image {
                altText
                title
                sizes(size: _1920X1080)
                sourceUrl(size: _1920X1080)
                srcSet(size: _1920X1080)
                mediaDetails {
                    height
                    width
              }
            }
            settings {
                headingLevel
                backgroundOverlay {
                    showBackgroundOverlay
                    backgroundOverlayStrength
                }
            }
        }
        settings {
            disableCisilionLogo
            enableExtraPadding
            enableShallowBanner
            showComponentTitle
            spacing
        }
    }
`
);

export default banner;
