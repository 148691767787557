const form = (postType) => (
    `... on ${postType}_Components_Components_Form {
        fieldGroupName
        title
        subtitle
        content
        formType
        hubspotFormRegionCode
        hubspotFormPortalId
        hubspotFormId
        settings {
            showComponentTitle
            spacing
            theme
        }
    }`
);

export default form;
