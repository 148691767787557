const solutions = (postType) => (`
    ... on ${postType}_Components_Components_Solutions {
        content
        fieldGroupName
        title
        buttonText
        image {
            altText
            title
            sizes(size: _1920X1080)
            sourceUrl(size: _1920X1080)
            srcSet(size: _1920X1080)
        }
        solutions {
            content
            title
            backgroundImage {
                altText
                title
                sizes(size: _1920X1080)
                sourceUrl(size: _1920X1080)
                srcSet(size: _1920X1080)
            }
            link {
                target
                title
                url
            }
            icon {
                mimeType
                sourceUrl(size: LARGE)
            }
        }
        settings {
            backgroundOverlay {
                showBackgroundOverlay
                backgroundOverlayStrength
            }
            showComponentTitle
            spacing
        }
    }
`
);

export default solutions;
