const industries = (postType) => (`
    ... on ${postType}_Components_Components_Industries {
        content
        fieldGroupName
        title
        settings {
            backgroundOverlay {
                showBackgroundOverlay
                backgroundOverlayStrength
            }
            theme
            showComponentTitle
            spacing
        }
        links {
            image {
                altText
                title
                sizes(size: _1920X1080)
                sourceUrl(size: _1920X1080)
                srcSet(size: _1920X1080)
            }
            link {
                target
                title
                url
            }
        }
    }
`
);

export default industries;
