const slidingCarousel = (postType) => (
    `... on ${postType}_Components_Components_SlidingCarousel {
        fieldGroupName
        title
        items {
            title
            image {
                altText
                title
                sizes(size: LARGE)
                sourceUrl(size: LARGE)
                srcSet(size: LARGE)
            }
            settings {
                enableRoundedImageCorners
                forceNativeImageSize
            }
        }
        settings {
            theme
            showComponentTitle
            spacing
        }
    }`
);

export default slidingCarousel;
