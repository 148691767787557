const logos = (postType) => (`
    ... on ${postType}_Components_Components_Logos {
        fieldGroupName
        title
        logos {
            logo {
                altText
                sizes(size: MEDIUM)
                sourceUrl(size: MEDIUM)
                srcSet(size: MEDIUM)
                title
            }
            link {
                target
                title
                url
            }
        }
        settings {
            theme
            showComponentTitle
            spacing
        }
    }
`
);

export default logos;
