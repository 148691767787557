const windowBanner = (postType) => (`
    ... on ${postType}_Components_Components_WindowBanner {
        fieldGroupName
        pretitle
        articles {
            ... on Post {
                id
                title
                slug
                date
                post {
                    featuredImage {
                        sizes(size: _1920X1080)
                        sourceUrl(size: _1920X1080)
                        srcSet(size: _1920X1080)
                    }
                    author {
                        ... on CisilionAuthor {
                            title
                            author {
                                company
                                linkedinProfile
                                position
                                featuredImage {
                                    sizes(size: MEDIUM)
                                    sourceUrl(size: MEDIUM)
                                    srcSet(size: MEDIUM)
                                }
                            }
                        }
                    }
                }
            }
        }
        settings {
            fieldGroupName
            showComponentTitle
            spacing
            theme
        }
    }
`
);

export default windowBanner;
