const blockquote = (postType) => (`
    ... on ${postType}_Components_Components_Blockquote {
        fieldGroupName
        quote
        settings {
            showComponentTitle
            spacing
            theme
        }
        author {
            authorName
            authorSubtitle
            authorImage {
                altText
                sizes(size: MEDIUM)
                sourceUrl(size: MEDIUM)
                srcSet(size: MEDIUM)
                title
            }
        }
    }
`
);

export default blockquote;
