const wysiwyg = (postType) => (`
    ... on ${postType}_Components_Components_Wysiwyg {
        fieldGroupName
        content
        inlineForm {
            showInlineForm
            hubspotFormId
            formTitle
            hideEventInformation
        }
        settings {
            theme
            showSharingLinks
            showTags
            showComponentTitle
            spacing
        }
    }
`
);

export default wysiwyg;
