const stats = (postType) => (`
    ... on ${postType}_Components_Components_Stats {
        fieldGroupName
        enableIntro
        intro {
            fieldGroupName
            supporting
            title
        }
        title
        settings {
            fieldGroupName
            showComponentTitle
            spacing
            theme
            disableLogoPattern
            disableKeyline
        }
        stats {
            prefix
            stat
            suffix
            secondaryText
        }
    }
`
);

export default stats;
