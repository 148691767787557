const sbsText = (postType) => (`
    ... on ${postType}_Components_Components_SbsText {
        content
        fieldGroupName
        settings {
            fieldGroupName
            reverseDirection
            spacing
            theme
            showComponentTitle
        }
        title
    }
`
);

export default sbsText;
