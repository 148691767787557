const jobRoles = (postType) => (`
    ... on ${postType}_Components_Components_JobRoles {
        fieldGroupName
        title
        settings {
            showComponentTitle
            spacing
        }
    }
`
);

export default jobRoles;
