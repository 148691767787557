const sbsTextColumns = (postType) => (`
    ... on ${postType}_Components_Components_SbsTextColumns {
        fieldGroupName
        title
        columns {
            title
            content
            link {
                url
                title
            }
        }
        settings {
            theme
            titlePosition
            showComponentTitle
            spacing
        }
    }
`
);

export default sbsTextColumns;
