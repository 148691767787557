const ctaBlock = (postType) => (`
    ... on ${postType}_Components_Components_CtaBlock {
        content
        fieldGroupName
        linkOptions
        link {
            target
            title
            url
        }
        file {
            title
            mediaItemUrl
        }
        hubspotFormId
        linkTitle
        settings {
            disableCisilionLogos
            theme
            showComponentTitle
            spacing
        }
        title
    }
`
);

export default ctaBlock;
