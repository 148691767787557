const sectionBanner = (postType) => (`
    ... on ${postType}_Components_Components_SectionBanner {
        content
        fieldGroupName
        image {
            altText
            title
            sizes(size: _1920X1080)
            sourceUrl(size: _1920X1080)
            srcSet(size: _1920X1080)
        }
        pretitle
        title
        settings {
            backgroundOverlayStrength
            headingLevel
            disableCisilionLogo
            fieldGroupName
            showComponentTitle
            spacing
        }
    }
`
);

export default sectionBanner;
