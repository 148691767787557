const sbsTextSlider = (postType) => (`
    ... on ${postType}_Components_Components_SbsTextSlider {
        fieldGroupName
        settings {
            showComponentTitle
            spacing
        }
        slides {
            content
            theme
            title
            image {
                altText
                sizes(size: _1920X1080)
                sourceUrl(size: _1920X1080)
                srcSet(size: _1920X1080)
                title
            }
            backgroundOverlay {
                showBackgroundOverlay
                backgroundOverlayStrength
            }
        }
    }
`
);

export default sbsTextSlider;
