const awards = (postType) => (`
    ... on ${postType}_Components_Components_Awards {
        fieldGroupName
        title
        awards {
            title
            subtitle
            image {
                altText
                title
                sizes(size: MEDIUM)
                sourceUrl(size: MEDIUM)
                srcSet(size: MEDIUM)
            }
            link {
                title
                target
                url
            }
        }
        settings {
            theme
            showComponentTitle
            spacing
        }
    }
`
);

export default awards;
