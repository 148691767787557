const verticalSlider = (postType) => (`
    ... on ${postType}_Components_Components_VerticalSlider {
        fieldGroupName
        title
        pretitle
        slides {
            description
            fieldGroupName
            title
            image {
                altText
                link
                title
                sizes(size: LARGE)
                sourceUrl(size: LARGE)
                srcSet(size: LARGE)
            }
        }
        settings {
            fieldGroupName
            theme
            showComponentTitle
            spacing
        }
    }
`);

export default verticalSlider;
