const heroVideo = (postType) => (`
    ... on ${postType}_Components_Components_HeroVideo {
        fieldGroupName
        pretitle
        title
        videoSource
        video
        streamyardEmbedCode
        image {
            altText
            sizes(size: _1920X1080)
            sourceUrl(size: _1920X1080)
            srcSet(size: _1920X1080)
            title
        }
        settings {
            theme
            showComponentTitle
            spacing
        }
    }
`
);

export default heroVideo;
