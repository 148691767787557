const video = (postType) => (`
    ... on ${postType}_Components_Components_Video {
        fieldGroupName
        title
        content
        videoSource
        video
        streamyardEmbedCode
        image {
            sizes(size: LARGE)
            sourceUrl(size: LARGE)
            srcSet(size: LARGE)
        }
        date
        settings {
            theme
            showComponentTitle
            spacing
            videoPlacement
        }
    }
`
);

export default video;
